.hide {
	display: none;
}
.cke_editor_editor1 {
	border: none !important;
}

.channel-size {
	background-size: 884px 178px;
}

.cke_button__image {
	display: none !important;
}
.cke_top {
	display: none !important;
}

.modal_overlay_style {
	display: unset;
	align-items: unset;
}

.modal_modal_style {
	padding: 0;
	background: unset;
}

.ck.off figure.image,
.ck.off figure.image:hover {
	outline: none;
}

.top-position {
	position: absolute;
	top: 58px;
	left: 0;
}

.scrollarea {
	position: relative;
	overflow: hidden;
}

.area {
	height: 330px;
}

.scrollarea-content {
	margin: 0;
	padding: 0;
	overflow: hidden;
	position: relative;
}

.scrollarea-content:focus {
	outline: 0;
}

.scrollarea {
	position: relative;
	overflow: hidden;
}

.scrollarea .scrollbar-container {
	position: absolute;
	background: none;
	opacity: 0.1;
	z-index: 9999;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
}

.scrollarea .scrollbar-container.horizontal {
	width: 100%;
	height: 10px;
	left: 0;
	bottom: 0;
}

.scrollarea .scrollbar-container.horizontal .scrollbar {
	width: 20px;
	height: 8px;
	background: #000;
	margin-top: 1px;
}

.scrollarea .scrollbar-container.vertical {
	width: 10px;
	height: 100%;
	right: 0;
	top: 0;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
	width: 8px;
	height: 20px;
	background: #000;
	margin-left: 1px;
}

.scrollarea .scrollbar-container.active,
.scrollarea .scrollbar-container:hover {
	background: gray;
	opacity: 0.6 !important;
}

.scrollarea:hover .scrollbar-container {
	opacity: 0.3;
}

/*로딩*/
.sk-folding-cube {
	margin: 20px auto;
	width: 40px;
	height: 40px;
	position: relative;
	-webkit-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #efefef;
	-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
	animation: sk-foldCubeAngle 2.4s infinite linear both;
	-webkit-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
	-webkit-transform: scale(1.1) rotateZ(90deg);
	transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
	-webkit-transform: scale(1.1) rotateZ(180deg);
	transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
	-webkit-transform: scale(1.1) rotateZ(270deg);
	transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
	0%,
	10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}

	25%,
	75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}

	90%,
	100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}

@keyframes sk-foldCubeAngle {
	0%,
	10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}

	25%,
	75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}

	90%,
	100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}

iframe:focus {
	outline: none;
}

/**
* oembera 제목 숨기기
*/
.embera-title-hidden {
	position: absolute;
	overflow: hidden;
	border: 0;
	width: 1px;
	height: 1px;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
}
/**
* 모바일 배경
*/
#cke_editor1 > div {
	background-color: transparent !important;
}
#cke_1_contents > iframe {
	background-color: transparent !important;
}

/**
* CKEditor 위젯 이동 Handler 수정
*/
.post_detail .txt_box span[style*="handle.png"] {
	display: none;
}
.post_detail .txt_box img[src*="R0lGODlhAQABAPABAP"] {
	display: none;
}


/**
* 홈 > 재생목록에 .allList 노출안함
*/
.home .vod-box .vod-info .info .allList {
	display: none;
}
